@import './vars.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: $bgColor;
    color: $textColor;
    font-family: 'Roboto Mono', monospace;
    padding-bottom: 5rem;
}

.container {
    max-width: 1300px;
    padding: 0 100px;
    margin: 0 auto;
    position: relative;

    @media (max-width: $mobileBreakpoint) {
        padding: 0 20px;
    }
}