@import "./vars.scss";

.landing {
    position: relative;

    .sectionTitle {
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 1.5rem;

        @media (max-width: $mobileBreakpoint) {
            font-size: 2rem;
            margin-bottom: 0.5rem;
        }
    }

    .sectionSubTitle {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
        color: $textSecColor;
        font-family: 'Roboto', sans-serif;
    }

    .blur {
        width: 100px;
        height: 100px;
        background-color: $accentColor;
        position: absolute;
        top: -150px;
        left: 100px;
        filter: blur(70px);
        z-index: -1;
    }

    #header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8rem;
        flex-wrap: wrap;

        @media (max-width: $mobileBreakpoint) {
            flex-direction: column-reverse;
            align-items: center;
        }

        .mainHeadCol {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 50%;
            min-width: 300px;
            text-align: center;

            @media (max-width: $mobileBreakpoint) {
                max-width: 100%;
                min-width: 100%;
            }
        }

        .pageTitle {
            font-size: 7rem;
            font-weight: 900;
            line-height: 7rem;

            .accent {
                color: $accentColor;
            }

            @media (max-width: $mobileBreakpoint) {
                font-size: 3rem;
                line-height: 3.3rem;
            }
        }

        .subtitle {
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 2rem;
            color: $textSecColor;
            margin-top: 2rem;
            font-family: 'Roboto', sans-serif;

            @media (max-width: $mobileBreakpoint) {
                font-size: 1rem;
                line-height: 1.4rem;
            }
        }

        .badge {
            position: relative;
            width: 200px;
            border-radius: 50%;
            transform: rotate(-50deg);
            animation: rotate 20s linear infinite;

            p span {
                font: 26px Monaco, MonoSpace;
                position: absolute;
                height: 100px;
                width: 20px;
                left: 0;
                top: 0;
                transform-origin: bottom center;
            }

            @for $i from 0 to 24 {
                .char#{$i} {
                    transform: rotate(($i * 15) + deg);
                }
            }

            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }

        .social {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            gap: 20px;

            .btn {
                color: white;
                background-color: rgba(128, 128, 128, 0.208);
                border-radius: 10px;
                display: flex;
                padding: 15px 20px;
                text-decoration: none;
                gap: 10px;
                align-items: center;
                border: 2px solid rgba(128, 128, 128, 0.271);
                transition: 0.2s;

                &:hover {
                    background-color: rgba(128, 128, 128, 0.271);
                }
            }
        }

        .header-image {
            width: 40%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                z-index: -1;
                filter: blur(70px);
            }

            .graphic {
                width: 95%;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                    transition: 0.5s;
                }

                @media (max-width: $mobileBreakpoint) {
                    margin-bottom: 2rem;
                }
            }

            @media (max-width: $mobileBreakpoint) {
                width: 50%;
                margin-bottom: 1rem;
            }
        }

        @media (max-width: $mobileBreakpoint) {
            margin-bottom: 3rem;
        }
    }

    .wave {
        aspect-ratio: 900/70;
        width: 100%;
        opacity: 0.5;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        &.top.layer {
            background-image: url("../assets/wave_1.svg");
        }

        &.bottom.layer {
            background-image: url("../assets/wave_2.svg");
        }
    }

    #work {
        padding-bottom: 50px;
        background-color: #081d2f;

        .work-grid {
            margin-top: 3rem;

            .work-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 4rem;
                flex-wrap: wrap;

                .icon {
                    width: 100px;
                    margin-right: 35px;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    @media (max-width: $mobileBreakpoint) {
                        width: 80px;
                        margin-right: 0;
                        margin-bottom: 2rem;
                    }
                }

                .content {
                    max-width: calc(100% - 200px);

                    .title {
                        font-size: 1.5rem;
                        font-weight: bold;
                        margin-bottom: 0.5rem;
                        display: flex;
                        align-items: center;

                        .check {
                            margin-left: 10px;
                            color: rgb(6, 175, 6);
                        }

                        @media (max-width: $mobileBreakpoint) {
                            font-size: 1.2rem;
                            justify-content: center;
                            margin-bottom: 1rem;
                        }
                    }

                    .desc {
                        font-size: 1rem;
                        color: $textSecColor;
                        font-family: 'Roboto', sans-serif;

                        @media (max-width: $mobileBreakpoint) {
                            text-align: center;
                        }
                    }

                    @media (max-width: $mobileBreakpoint) {
                        max-width: 100%;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: $mobileBreakpoint) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        @media (max-width: $mobileBreakpoint) {
            padding-top: 2rem;
        }
    }

    #volunteering {
        padding-bottom: 50px;
        margin-top: 100px;

        .work-grid {
            margin-top: 3rem;

            .work-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 4rem;
                flex-wrap: wrap;

                .icon {
                    width: 100px;
                    margin-right: 35px;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    @media (max-width: $mobileBreakpoint) {
                        width: 80px;
                        margin-right: 0;
                        margin-bottom: 2rem;
                    }
                }

                .content {
                    max-width: calc(100% - 200px);

                    .title {
                        font-size: 1.5rem;
                        font-weight: bold;
                        margin-bottom: 0.5rem;
                        display: flex;
                        align-items: center;

                        .check {
                            margin-left: 10px;
                            color: rgb(6, 175, 6);
                        }

                        @media (max-width: $mobileBreakpoint) {
                            font-size: 1.2rem;
                            justify-content: center;
                            margin-bottom: 1rem;
                        }
                    }

                    .desc {
                        font-size: 1rem;
                        color: $textSecColor;
                        font-family: 'Roboto', sans-serif;

                        @media (max-width: $mobileBreakpoint) {
                            text-align: center;
                        }
                    }

                    @media (max-width: $mobileBreakpoint) {
                        max-width: 100%;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: $mobileBreakpoint) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        @media (max-width: $mobileBreakpoint) {
            padding-top: 2rem;
        }
    }

    #skills {
        margin-top: 5rem;

        .skill-list {
            margin-top: 3rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;

            .skill {
                flex: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 2rem;
                background-color: $bgSecColor;
                border-radius: 10px;

                .icon {
                    height: 100px;
                    margin-bottom: 1rem;
                    font-size: 6rem;
                    display: flex;
                    justify-content: center;

                    img {
                        max-width: 130px;
                        max-height: 90px;
                    }
                }

                .name {
                    text-align: center;
                    font-size: 2rem;
                    font-weight: bold;
                }
            }
        }
    }
}
